import React from "react";

import CommonStyle from "common/styles/common.css";
import YbTooltip from "common/components/yb-tooltip";
import YbIndicator from "common/components/yb-indicator";

import Styles from "../styles/summary.css";
import YbInventoryItem from "../components/yb-inventory-item";
import YbStatus from "../components/yb-status";
import YbHider from "../components/yb-hider";

class YbYieldSet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPlacements: props.showPlacements,
      collectionRef: null,
      publisherId: props.publisherId,
    };
  }

  componentDidMount() {
    var collectionRef = this.refs.placements || this.refs.adUnits;
    var yieldSetRef = this.refs.yieldSet;
    var boundingClientRect = null;
    var scrollTo = 0;
    var headerHeight = 72;

    this.state.collectionRef = collectionRef;

    if (this.state.scrollTo) {
      delete this.state.scrollTo;

      setTimeout(() => {
        boundingClientRect = yieldSetRef.getBoundingClientRect();
        scrollTo = boundingClientRect.y - headerHeight;
        window.scroll(0, scrollTo);
      }, 1000);
    }
  }

  newInventory(yieldSet, event) {
    if (this.state.publisherId > 0) {
      this.props.history.push(
        `/publisher/${this.state.publisherId}/inventory/create/${yieldSet.yieldSetId}`
      );
    } else {
      this.props.history.push(`/inventory/create/${yieldSet.yieldSetId}`);
    }

    event.stopPropagation();
  }

  editYieldSet(yieldSet, event) {
    if (this.state.publisherId > 0) {
      this.props.history.push(
        `/publisher/${this.state.publisherId}/yield-sets/update/${yieldSet.yieldSetId}`
      );
    } else {
      this.props.history.push(`/yield-sets/update/${yieldSet.yieldSetId}`);
    }

    event.stopPropagation();
  }

  editAdUnit(adUnit, event) {
    if (this.state.publisherId > 0) {
      this.props.history.push(
        `/publisher/${this.state.publisherId}/ad-units/update/${adUnit.adUnitId}`
      );
    } else {
      this.props.history.push(`/ad-units/update/${adUnit.adUnitId}`);
    }

    event.stopPropagation();
  }

  editPlacement(placement, event) {
    if (this.state.publisherId > 0) {
      this.props.history.push(
        `/publisher/${this.state.publisherId}/placements/update/${placement.placementId}`
      );
    } else {
      this.props.history.push(`/placements/update/${placement.placementId}`);
    }

    event.stopPropagation();
  }

  toggleInventoryItemStatus(item) {
    return this.props.toggleInventoryItemStatus(item);
  }

  toggleYieldSet(yieldSet, isAdUnit) {
    return this.props.toggleYieldSet(yieldSet, isAdUnit);
  }

  toggleYieldSetContent(yieldSet) {
    return this.props.toggleYieldSetContent(yieldSet);
  }

  renderPlacements(network, yieldSet, refetch) {
    var accountConfig = this.props.accountConfig;
    var props = {};

    return yieldSet.matchedPlacements.map((placement, placementIndex) => {
      if (placementIndex === yieldSet.matchedPlacements.length - 1) {
        props.ref = "lastItem";
      }

      return (
        <span key={placementIndex} {...props}>
          <YbInventoryItem
            isPlacement={true}
            accountConfig={accountConfig}
            network={network}
            yieldSet={yieldSet}
            item={placement}
            itemIndex={placementIndex}
            wrapperClass={Styles.placements}
            toggleItem={this.toggleInventoryItemStatus.bind(this)}
            editItem={this.editPlacement.bind(this)}
            editTooltip={i18n`EDIT_PLACEMENT`}
            disableEdit={!accountConfig.gamPlacement.enabled}
          />
        </span>
      );
    });
  }

  renderAdUnits(network, yieldSet, refetch) {
    var accountConfig = this.props.accountConfig;

    return yieldSet.matchedAdUnits.map((adUnit, adUnitIndex) => {
      return (
        <YbInventoryItem
          isPlacement={false}
          accountConfig={accountConfig}
          key={adUnitIndex}
          network={network}
          yieldSet={yieldSet}
          item={adUnit}
          itemIndex={adUnitIndex}
          wrapperClass={Styles.adUnits}
          toggleItem={this.toggleInventoryItemStatus.bind(this)}
          editItem={this.editAdUnit.bind(this)}
          editTooltip={i18n`EDIT_AD_UNIT`}
          disableEdit={!accountConfig.gamAdUnit.enabled}
        />
      );
    });
  }

  render() {
    var accountConfig = this.props.accountConfig;
    var totalAdUnits = this.props.totalAdUnits;
    var totalPlacements = this.props.totalPlacements;
    var network = this.props.network;
    var yieldSet = this.props.yieldSet;
    var accordion = this.props.accordion;
    var yieldSetBtnClass = CommonStyle.button + " " + Styles.yieldSetBtn;
    var stopBtn = this.props.stopBtn;
    var runBtn = this.props.runBtn;
    var yieldSetIndex = this.props.yieldSetIndex;
    var refetch = this.props.refetch;
    var showAdUnits = this.props.showAdUnits;
    var showPlacements = this.props.showPlacements;
    var showContent = this.props.showContent;
    var collectionRef = this.state.collectionRef;
    var adUnitCounter = yieldSet.matchedAdUnits.filter((adUnit) =>
      adUnit.problems.find((problem) => problem !== "NORMAL")
    ).length;
    var placementCounter = yieldSet.matchedPlacements.filter((placement) =>
      placement.problems.find((problem) => problem !== "NORMAL")
    ).length;
    var className = [
      Styles.yieldSet,
      showContent === null
        ? ""
        : showContent
        ? Styles.showContent
        : Styles.hideContent,
      showAdUnits === null
        ? ""
        : showAdUnits
        ? Styles.showAdUnits
        : Styles.hideAdUnits,
      showPlacements === null
        ? ""
        : showPlacements
        ? Styles.showPlacements
        : Styles.hidePlacements,
    ].join(" ");

    var accountHelper = "(adSense & ADX)";
    var canCreateAdUnit = accountConfig.gamAdUnit.enabled;
    var canCreatePlacement = accountConfig.gamPlacement.enabled;
    var showNewInventoryItemBtn = null;

    if (accordion.yieldSet[yieldSet.yieldSetId].scrollTo) {
      delete accordion.yieldSet[yieldSet.yieldSetId].scrollTo;
      this.state.scrollTo = true;
    }

    if (showPlacements !== this.state.showPlacements) {
      setTimeout(() => {
        this.setState({
          showPlacements: showPlacements,
        });
      });
    }

    if (canCreateAdUnit && accountConfig.gamAdUnit.gamAdUnitCap !== -1) {
      if (totalAdUnits >= accountConfig.gamAdUnit.gamAdUnitCap) {
        canCreateAdUnit = false;
      }
    }

    if (
      canCreatePlacement &&
      accountConfig.gamPlacement.gamPlacementCap !== -1
    ) {
      if (totalPlacements >= accountConfig.gamPlacement.gamPlacementCap) {
        canCreatePlacement = false;
      }
    }

    showNewInventoryItemBtn = canCreateAdUnit || canCreatePlacement;

    if (!yieldSet.adsenseAccount) {
      accountHelper = "(ADX)";
    }

    if (!yieldSet.adxAccount) {
      accountHelper = "(adSense)";
    }

    return (
      <div className={className} ref="yieldSet">
        <span className={Styles.connector} />
        <div
          className={`row ${Styles.titleWrapper}`}
          onClick={this.toggleYieldSetContent.bind(this, yieldSet)}
        >
          <div className="col-md-9">
            <span
              className={`${Styles.caret} ${CommonStyle.problemIndicatorWrapper}`}
            >
              {showContent ? (
                <i className="fa fa-angle-down" />
              ) : (
                <span>
                  <i className="fa fa-angle-right" />
                  <YbIndicator counter={adUnitCounter + placementCounter} />
                </span>
              )}
            </span>
            <span>
              {yieldSet.name} {accountHelper} (
              {yieldSet.matchedAdUnits.length +
                yieldSet.matchedPlacements.length}
              )
            </span>
            <YbStatus
              item={yieldSet}
              index={yieldSetIndex}
              total={network.yieldSetsVM.length}
              showStatus={false}
            />
          </div>
          <div className="col-md-3">
            <div className={Styles.actionsWrapper}>
              {runBtn}
              {stopBtn}
              {showNewInventoryItemBtn ? (
                <YbTooltip message={i18n`NEW_INVENTORY_ITEM`} position="left">
                  <span
                    className={yieldSetBtnClass}
                    onClick={this.newInventory.bind(this, yieldSet)}
                  >
                    <i className="fa fa-plus" />
                  </span>
                </YbTooltip>
              ) : (
                <span className={yieldSetBtnClass} disabled>
                  <i className="fa fa-plus" />
                </span>
              )}
              {accountConfig.yieldSet.enabled ? (
                <YbTooltip message={i18n`EDIT_YIELD_SET`} position="left">
                  <span
                    className={yieldSetBtnClass}
                    onClick={this.editYieldSet.bind(this, yieldSet)}
                  >
                    <i className="fa fa-pencil" />
                  </span>
                </YbTooltip>
              ) : (
                <span className={yieldSetBtnClass} disabled>
                  <i className="fa fa-pencil" />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={`${Styles.content}`}>
          <div ref="adUnits">
            <div
              className={`row ${Styles.titleWrapper} ${Styles.inventoryLabel}`}
              onClick={this.toggleYieldSet.bind(this, yieldSet, true)}
            >
              <span className={Styles.connector} />
              <div className="col-md-12">
                <span
                  className={`${Styles.caret} ${CommonStyle.problemIndicatorWrapper}`}
                >
                  {showAdUnits ? (
                    <i className="fa fa-angle-down" />
                  ) : (
                    <span>
                      <i className="fa fa-angle-right" />
                      <YbIndicator counter={adUnitCounter} />
                    </span>
                  )}
                </span>
                <span>
                  {i18n`AD_UNITS`} ({yieldSet.matchedAdUnits.length})
                </span>
              </div>
            </div>
            {this.renderAdUnits(network, yieldSet, refetch)}
          </div>
          {accountConfig.gamPlacement.enabled && (
            <div ref="placements">
              <div
                className={`row ${Styles.titleWrapper} ${Styles.inventoryLabel}`}
                onClick={this.toggleYieldSet.bind(this, yieldSet, false)}
              >
                <span className={Styles.connector} />
                <div className="col-md-12">
                  <span
                    className={`${Styles.caret} ${CommonStyle.problemIndicatorWrapper}`}
                  >
                    {showPlacements ? (
                      <i className="fa fa-angle-down" />
                    ) : (
                      <span>
                        <i className="fa fa-angle-right" />
                        <YbIndicator counter={placementCounter} />
                      </span>
                    )}
                  </span>
                  <span>
                    {i18n`PLACEMENTS`} ({yieldSet.matchedPlacements.length})
                  </span>
                </div>
              </div>
              {this.renderPlacements(network, yieldSet, refetch)}
            </div>
          )}
        </div>
        {showContent && !showPlacements && (
          <YbHider
            direct={true}
            collectionRef={collectionRef}
            lineHeight={17}
          />
        )}
        {showContent && showPlacements && (
          <YbHider
            direct={true}
            collectionRef={collectionRef}
            lineHeight={17}
          />
        )}
      </div>
    );
  }
}

module.exports = YbYieldSet;

import gql from "graphql-tag";

export const queryUsers = gql`
  query users($count: Int!, $cursor: ID, $pubId: Int) {
    users(first: $count, after: $cursor, assumePubId: $pubId)
      @connection(key: users) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          userId
          email
          role
        }
      }
    }
  }
`;

export const inviteUser = gql`
  mutation invite($email: EmailAddress!, $role: UserRole, $pubId: Int) {
    inviteUsers(input: { emails: [$email], role: $role }, assumePubId: $pubId) {
      users {
        id
        userId
        email
        role
      }
    }
  }
`;

export const updateUser = gql`
  mutation update($userId: Int!, $role: UserRole!, $pubId: Int) {
    updateUser(input: { userId: $userId, role: $role }, assumePubId: $pubId) {
      user {
        id
        userId
        email
        role
      }
    }
  }
`;

export const removeUser = gql`
  mutation remove($userId: Int!, $pubId: Int) {
    removeUser(input: { userId: $userId }, assumePubId: $pubId) {
      result
    }
  }
`;

import React from "react";
import { Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import FormStyle from "common/styles/forms.css";
import YbModal from "common/components/yb-modal";
import YbLoading from "common/components/yb-loading";

import { PERMISSION_ROLE } from "../../_newapp/common/constants/common";
import { updateUser } from "../query/user";

class UpdateUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      userRole: props.user.role,
    };

    this.handleUserRoleChange = this.handleUserRoleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  handleUserRoleChange(e) {
    const role = e.target.value;
    this.setState({
      userRole: role,
    });
  }

  async handleUpdate(updateUserFn) {
    const params = {
      variables: {
        userId: this.props.user.userId,
        role: this.state.userRole,
      },
    };
    const r = await updateUserFn(params);

    this.props.closeModal();
    this.props.refetch();
  }

  render() {
    const { userRole } = this.state;
    const { user, inModal, closeModal } = this.props;

    return (
      <Mutation mutation={updateUser}>
        {(updateUserFn, { loading, error, data }) => {
          const submitting = loading;
          return (
            <YbModal
              show={inModal}
              title={user.email}
              onHide={closeModal}
              onSubmit={() => this.handleUpdate(updateUserFn)}
              successText={"Change role"}
              showSuccessIcon={false}
              error={error}
              disableSubmit={submitting}
              // disabled={submitting || reloadExternalSource}
            >
              <div>
                <span className={CommonStyle.textView}>
                  <div
                    className={`${CommonStyle.textAlignLeft}`}
                    style={{
                      fontWeight: "bold",
                      fontSize: "larger",
                    }}
                  >
                    Change role:
                  </div>

                  <div className={`${CommonStyle.textAlignCenter}`}>
                    <div className={CommonStyle.textAlignLeft}>
                      {_.values(PERMISSION_ROLE).map((role) => {
                        return (
                          <label
                            key={role}
                            className={`${FormStyle.inputRadio}`}
                          >
                            <input
                              type="radio"
                              value={role}
                              name="radio_product"
                              onChange={this.handleUserRoleChange}
                              checked={userRole === role}
                              disabled={submitting}
                            />{" "}
                            {role}
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </span>
              </div>
              {loading && <YbLoading></YbLoading>}
            </YbModal>
          );
        }}
      </Mutation>
    );
  }
}

module.exports = UpdateUserModal;

import React from "react";
import { Query } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import YbPagedTableView from "common/components/yb-paged-table-view";
import YbLoadingView from "common/components/yb-loading-view";
import { ACCOUNT_CONFIG } from "common/query/publisher";

import { queryUsers } from "../query/user";
import { UserInfoContext } from "../../_newapp/common/contexts/contexts";
import UpdateUserBtn from "../components/update-user-btn";
import RemoveUserBtn from "../components/remove-user-btn";
import CreateUserBtn from "../components/create-user-btn";

const count = 1000;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var accountConfig = null;
    var usersCount = 0;

    return (
      <React.Fragment>
        <UserInfoContext.Consumer>
          {({ userInfo }) => {
            return (
              <Query
                query={ACCOUNT_CONFIG}
                variables={{ pubId: this.props.publisherId }}
                fetchPolicy={"network-only"}
              >
                {({ loading, error, data, refetch }) => {
                  if (loading) {
                    return <YbLoadingView />;
                  }

                  if (error) {
                    return (
                      <div className={`${CommonStyle.wrapper}`}>
                        <div
                          className={`${CommonStyle.textDanger} ${CommonStyle.textView}`}
                        >
                          {i18n`ERROR_LOADING_ACCOUNT_CONFIG_ELLIPSIS`}
                        </div>
                      </div>
                    );
                  }

                  if (
                    data &&
                    data.accountConfig &&
                    !accountConfig &&
                    userInfo
                  ) {
                    accountConfig = _.defaults(
                      userInfo.accountConfig
                        ? userInfo.accountConfig.userManagementConfig
                        : {},
                      data.accountConfig.config.userManagementConfig
                    );
                  }

                  return (
                    <div className={CommonStyle.wrapper}>
                      <Query
                        query={queryUsers}
                        variables={{
                          count: count,
                          pubId: this.props.publisherId,
                        }}
                      >
                        {({ data, loading, fetchMore, error, refetch }) => {
                          if (loading) {
                            return <div />;
                          }

                          if (error) {
                            return <div>{error.message}</div>;
                          }

                          usersCount = data.users.edges.length;

                          const columns = [
                            {
                              name: "Email",
                              key: "email",
                            },
                            {
                              name: "Role",
                              key: "role",
                            },
                            {
                              name: "Actions",
                              key: "actions",
                            },
                          ];

                          data.users = data.users || {
                            edges: [],
                            pageInfo: {},
                          };

                          const rows = data.users.edges.map(({ node }) => {
                            const isSelf = userInfo.user.email === node.email;

                            return {
                              id: node.id,
                              data: {
                                email: node.email,
                                role: node.role,
                                actions: [
                                  <UpdateUserBtn
                                    isDisabled={isSelf}
                                    user={node}
                                    refetch={refetch}
                                  ></UpdateUserBtn>,
                                  <RemoveUserBtn
                                    isDisabled={isSelf}
                                    user={node}
                                    refetch={refetch}
                                  ></RemoveUserBtn>,
                                ],
                              },
                            };
                          });

                          return (
                            <YbPagedTableView
                              title={i18n`USERS`}
                              hasMore={data.users.pageInfo.hasNextPage}
                              count={count}
                              rows={rows}
                              columns={columns}
                              onUpdateCursor={(cursor) => {
                                return fetchMore({
                                  variables: {
                                    count: count,
                                    cursor: cursor,
                                    pubId: this.props.publisherId,
                                  },
                                  updateQuery: (prev, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) {
                                      return prev;
                                    }

                                    fetchMoreResult.users.edges = [
                                      ...prev.users.edges,
                                      ...fetchMoreResult.users.edges,
                                    ];
                                    return fetchMoreResult;
                                  },
                                });
                              }}
                            >
                              <CreateUserBtn refetch={refetch}></CreateUserBtn>
                            </YbPagedTableView>
                          );
                        }}
                      </Query>
                    </div>
                  );
                }}
              </Query>
            );
          }}
        </UserInfoContext.Consumer>
      </React.Fragment>
    );
  }
}

export default List;

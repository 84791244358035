import React from "react";
import ReactDOM from "react-dom";
import MainApp from "./main-app";

import "react-bootstrap";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/font-awesome/css/font-awesome.min.css";

// for react date range library
import "../../node_modules/react-date-range/dist/styles.css";
import "../../node_modules/react-date-range/dist/theme/default.css";

function App() {
  return <MainApp />;
}

ReactDOM.render(<App />, document.getElementById("newroot"));

import React from "react";

import CommonStyle from "common/styles/common.css";
import { Query, Mutation } from "react-apollo";
import { removeUser } from "../query/user";

class RemoveUserBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleRemoveUser = this.handleRemoveUser.bind(this);
  }

  async handleRemoveUser(removeUserFn) {
    const { user } = this.props;
    const userConfirm = window.confirm(`Remove this user: ${user.email}?`);
    if (!userConfirm) return;

    const params = {
      variables: {
        userId: this.props.user.userId,
      },
    };
    const r = await removeUserFn(params);

    this.props.refetch();
  }

  render() {
    var btnClass = this.props.btnClass || "";
    const { isDisabled } = this.props;

    return (
      <Mutation mutation={removeUser}>
        {(removeUserFn, { loading, error, data }) => {
          return (
            <div style={{ display: "inline-block" }}>
              <button
                type="button"
                className={`${CommonStyle.buttonDanger} ${btnClass}`}
                onClick={() => this.handleRemoveUser(removeUserFn)}
                disabled={isDisabled}
              >
                Remove
              </button>
              {error && <span className="text-danger">{error}</span>}
            </div>
          );
        }}
      </Mutation>
    );
  }
}

module.exports = RemoveUserBtn;

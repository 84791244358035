import React, { useState, useEffect } from "react";
import { withApollo } from "react-apollo";

import { GET_USER_INFO } from "./../../base/query/user-info";
import {
  USER_ROLE,
  TRIAL_STATUS,
  SUBSCRIPTION_STATUS,
  PERMISSION_ROLE,
  PERMISSION_ROLE_BY_NAME,
} from "./../constants/common";
import { UserInfoContext } from "./contexts";
import YBLoadingView from "./../../../common/components/yb-loading-view";
import PermissionDeniedPage from "../../base/states/permission-denied";

function UserInfoContextProvider(props) {
  let ignore = false;

  const loginAsPubId = props.loginAsPubId;

  const [userInfo, setUserInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();

  useEffect(() => {
    if (!ignore) {
      getUserInfo();
    }

    return function cleanUp() {
      ignore = true;
    };
  }, []);

  async function getUserInfo() {
    setIsLoading(true);
    const { client } = props;
    try {
      const tempUserInfo = await queryUserInfo(client);

      let properUserInfo = transformUserInfo(tempUserInfo, loginAsPubId);
      // console.log("get userInfo", properUserInfo, loginAsPubId);

      setUserInfo(properUserInfo);
      setError();
    } catch (err) {
      console.log("getUserInfo Error", err);
      setError(err);
    }
    setIsLoading(false);
  }

  function handleUpdateUserInfo() {
    console.log("updating user info");
    getUserInfo();
  }

  if (error) {
    return <PermissionDeniedPage></PermissionDeniedPage>;
  }

  return (
    <UserInfoContext.Provider value={{ userInfo, handleUpdateUserInfo }}>
      {isLoading ? (
        <YBLoadingView />
      ) : (
        <React.Fragment>{props.children}</React.Fragment>
      )}
    </UserInfoContext.Provider>
  );
}

async function queryUserInfo(client) {
  try {
    const response = await client.query({
      query: GET_USER_INFO,
      fetchPolicy: "network-only",
    });
    const data = await response.data;
    return data;
  } catch (err) {
    console.log("Error querying userInfo", err);
    throw "Unable to identify user";
  }
}

function transformUserInfo(tempUserInfo, loginAsPubId) {
  const associatedPublishers = _.get(
    tempUserInfo,
    "me.associatedPublishers",
    []
  );
  const publisher = _.find(associatedPublishers, (p) => {
    return p.publisherId == loginAsPubId; // loginAsPubId is string
  });

  const publisherType = publisher.type;
  let userRole = getUserRoleFromPublisherType(publisherType);

  let properUserInfo = {
    role: userRole,
    user: {
      userId: _.get(tempUserInfo, "me.userId"),
      email: _.get(tempUserInfo, "me.email"),
      roleId: _.get(tempUserInfo, "me.roleId"),
      associatedPublishers,
    },
  };
  // Replace the decrecated accountConfig for permission control!
  const permissionRole = properUserInfo.user.roleId;
  let permissionConfig = _.get(tempUserInfo, "accountConfig.config");
  if (permissionRole === PERMISSION_ROLE_BY_NAME.VIEWER) {
    _.set(permissionConfig, ["userManagementConfig", "user", "enabled"], false);
    _.set(
      permissionConfig,
      ["userManagementConfig", "user", "userInvitation", "enabled"],
      false
    );
    _.set(permissionConfig, ["resourceConfig", "gamAccount", "enabled"], false);
    _.set(permissionConfig, ["resourceConfig", "gamNetwork", "enabled"], false);
    _.set(permissionConfig, ["resourceConfig", "yieldSet", "enabled"], false);
    _.set(permissionConfig, ["resourceConfig", "gamAdUnit", "enabled"], false);
  }
  if (permissionRole === PERMISSION_ROLE_BY_NAME.OPERATOR) {
    _.set(permissionConfig, ["userManagementConfig", "user", "enabled"], false);
    _.set(
      permissionConfig,
      ["userManagementConfig", "user", "userInvitation", "enabled"],
      false
    );
  }
  properUserInfo.accountConfig = permissionConfig;

  // const publisherId = _.get(tempUserInfo, "me.publisher.publisherId");
  const publisherId = loginAsPubId;
  // const pubName = _.get(tempUserInfo, "me.publisher.name");
  const pubName = publisher.name;
  const isRegisteredPublisher = publisher.registered === "REGISTERED";
  // const accountType = _.get(tempUserInfo, "me.publisher.accountType");
  const accountType = publisher.accountType;
  const isBillablePublisher = _.has(
    publisher,
    "billingSetting.activeBillingProfile.profileId"
  );
  const accountConfig = _.get(tempUserInfo, "accountConfig.config");

  switch (userRole) {
    case USER_ROLE.RESELLER_ADMIN: {
      properUserInfo.reseller = {
        publisherId,
        name: pubName,
        type: publisherType,
        isRegistered: isRegisteredPublisher,
        accountType,
        isBillablePublisher,
        config: accountConfig,
      };

      break;
    }

    case USER_ROLE.MANAGED_PUBLISHER_ADMIN: {
      const isInFreeTrial =
        _.get(
          tempUserInfo,
          "publisherAccountStatus.status.trialStatus.remainingDays"
        ) > 0;

      properUserInfo.reseller = {
        // name: _.get(tempUserInfo, "me.publisher.reseller.name"),
        name: _.get(publisher, "reseller.name"),
      };

      properUserInfo.publisher = {
        publisherId,
        name: pubName,
        type: publisherType,
        isRegistered: isRegisteredPublisher,
        accountType,
        isInFreeTrial,
        trialStatus: getTrialStatus(tempUserInfo, publisher),
        subscriptionStatus: getSubscriptionStatus(publisher),
        config: accountConfig,
      };

      if (isInFreeTrial) {
        const trialInfo = {
          remainingDays: _.get(
            tempUserInfo,
            "publisherAccountStatus.status.trialStatus.remainingDays"
          ),
          billableStartDate: _.get(
            tempUserInfo,
            "publisherAccountStatus.status.trialStatus.billableStartDate"
          ),
        };
        properUserInfo.publisher.trialInfo = trialInfo;
      }

      break;
    }

    case USER_ROLE.DIRECT_PUBLISHER_ADMIN: {
      const isInFreeTrial =
        _.get(
          tempUserInfo,
          "publisherAccountStatus.status.trialStatus.remainingDays"
        ) > 0;

      properUserInfo.publisher = {
        publisherId,
        name: pubName,
        type: publisherType,
        isRegistered: isRegisteredPublisher,
        accountType,
        isBillablePublisher,
        isInFreeTrial,
        trialStatus: getTrialStatus(tempUserInfo, publisher),
        subscriptionStatus: getSubscriptionStatus(publisher),
        config: accountConfig,
      };

      if (isInFreeTrial) {
        const trialInfo = {
          remainingDays: _.get(
            tempUserInfo,
            "publisherAccountStatus.status.trialStatus.remainingDays"
          ),
          billableStartDate: _.get(
            tempUserInfo,
            "publisherAccountStatus.status.trialStatus.billableStartDate"
          ),
        };
        properUserInfo.publisher.trialInfo = trialInfo;
      }

      break;
    }
  }

  // console.log(properUserInfo);
  return properUserInfo;
}

function getSubscriptionStatus(publisher) {
  // SUBSCRIBED, NOT_SUBSCRIBED
  const accountType = publisher.accountType;
  if (accountType === "ACTIVE") {
    return SUBSCRIPTION_STATUS.SUBSCRIBED;
  }

  return SUBSCRIPTION_STATUS.NOT_SUBSCRIBED;
}

function getTrialStatus(tempUserInfo, publisher) {
  // TRIAL, TRIAL_EXPIRED
  const accountType = publisher.accountType;
  if (accountType === "TRIAL_EXPIRED") {
    return TRIAL_STATUS.TRIAL_EXPIRED;
  }

  const remainingDays = _.get(
    tempUserInfo,
    "publisherAccountStatus.status.trialStatus.remainingDays"
  );
  if (remainingDays > 0) {
    return TRIAL_STATUS.TRIAL;
  }
}

function getUserRoleFromPublisherType(publisherType) {
  let userRole = null;
  switch (publisherType) {
    case "DIRECT_PUBLISHER": {
      userRole = USER_ROLE.DIRECT_PUBLISHER_ADMIN;
      break;
    }

    case "RESELLER": {
      userRole = USER_ROLE.RESELLER_ADMIN;
      break;
    }

    case "RESELLER_PUBLISHER": {
      userRole = USER_ROLE.MANAGED_PUBLISHER_ADMIN;
      break;
    }

    default: {
      userRole = USER_ROLE.UNRECOGNIZED_USER;
    }
  }

  return userRole;
}

export default withApollo(UserInfoContextProvider);

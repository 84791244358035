import React from "react";
import { Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import FormStyle from "common/styles/forms.css";
import YbLoading from "common/components/yb-loading";
import YbModal from "common/components/yb-modal";

import { PERMISSION_ROLE } from "../../_newapp/common/constants/common";
import { inviteUser } from "../query/user";

class CreateUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      email: "",
      userRole: "ADMIN", // default
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleUserRoleChange = this.handleUserRoleChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  handleEmailChange(e) {
    const email = e.target.value;
    this.setState({
      email,
    });
  }

  handleUserRoleChange(e) {
    const role = e.target.value;
    this.setState({
      userRole: role,
    });
  }

  async handleCreate(createUserFn) {
    const params = {
      variables: {
        email: this.state.email,
        role: this.state.userRole,
      },
    };
    const r = await createUserFn(params);
    console.log(r);

    this.props.closeModal();
    this.props.refetch();
  }

  render() {
    const { email, userRole } = this.state;
    const { inModal, closeModal } = this.props;

    return (
      <Mutation mutation={inviteUser}>
        {(createUserFn, { loading, error, data }) => {
          const submitting = loading;
          return (
            <YbModal
              show={inModal}
              title={"Add user"}
              onHide={closeModal}
              onSubmit={() => this.handleCreate(createUserFn)}
              successText={"Add"}
              showSuccessIcon={false}
              error={error}
              disableSubmit={submitting}
              // disabled={submitting || reloadExternalSource}
            >
              {" "}
              <div>
                <div
                  className={FormStyle.inputGroup}
                  style={{ marginBottom: "8px" }}
                >
                  <div
                    className={`${CommonStyle.textAlignLeft}`}
                    style={{
                      fontWeight: "bold",
                      fontSize: "larger",
                    }}
                  >
                    Email:
                  </div>

                  <div
                    className={`${FormStyle.form} ${FormStyle.formNoPadding}`}
                  >
                    <input
                      value={email}
                      type="email"
                      className="form-control"
                      required
                      disabled={submitting}
                      placeholder={"example@domain.com"}
                      onChange={this.handleEmailChange}
                    />
                  </div>
                </div>

                <div className={FormStyle.inputGroup}>
                  <div
                    className={`${CommonStyle.textAlignLeft}`}
                    style={{
                      fontWeight: "bold",
                      fontSize: "larger",
                    }}
                  >
                    Role:
                  </div>

                  <div className={CommonStyle.textAlignLeft}>
                    {_.values(PERMISSION_ROLE).map((role) => {
                      return (
                        <label key={role} className={`${FormStyle.inputRadio}`}>
                          <input
                            type="radio"
                            value={role}
                            name="radio_product"
                            onChange={this.handleUserRoleChange}
                            checked={userRole === role}
                            disabled={submitting}
                          />{" "}
                          {role}
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
              {loading && <YbLoading></YbLoading>}
            </YbModal>
          );
        }}
      </Mutation>
    );
  }
}

module.exports = CreateUserModal;

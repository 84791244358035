import React from "react";
import Styles from "../../../common/styles/common.css";
import { API_ENDPOINT } from "../../common/constants/common";

function InvitationFromReseller(props) {
  const { hash, reseller, publisher } = props.inviteData;

  function handleSignUp() {
    const querystring = `pId=${publisher.publisherId}&rId=${reseller.resellerId}&h=${hash}`; // case sensitive!
    const redirectUrl = `${window.location.origin}/#/invite/register?${querystring}`;
    console.log(redirectUrl);

    const loginEndpoint = `${API_ENDPOINT}/login?redirect_uri=${encodeURIComponent(
      redirectUrl
    )}`;

    window.location.href = loginEndpoint;
  }

  return (
    <div>
      <h2>
        You have been invited to join Reseller{" "}
        <span style={{ color: "#0348b7", fontWeight: "bolder" }}>
          {reseller.name}
        </span>{" "}
        to optimize with Intowow.
      </h2>

      <hr />
      <p>
        By clicking the sign up button below, you will be joining under the name{" "}
        <b>{publisher.name}</b>.
      </p>
      <p>
        Reseller{" "}
        <span style={{ color: "#0348b7", fontWeight: "bolder" }}>
          {reseller.name}
        </span>{" "}
        will be able to manage your inventory through Intowow console.
      </p>

      <div className="text-center">
        <button onClick={handleSignUp} className={Styles.roundButton}>
          <i className="fa fa-google" /> Sign up and join with Google
        </button>

        <div className="mt-2">
          <div className={Styles.termsWrapper}>
            <span>{i18n`By continuing, you agree to our`}</span>{" "}
            <span>
              <a href="#/terms" target="_blank">
                Service Agreement
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvitationFromReseller;

import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

import {
  DIRECT_PUBLISHER_LINKS,
  MANAGED_PUBLISHER_LINKS,
  RESELLER_LINKS,
  RESELLER_CONTROLLING_PUBLISHER_LINKS,
  VIEWER_DISABLE_LINKS,
  OPERATOR_DISABLE_LINKS,
} from "../constants/links-config";

import PATHNAME from "../constants/routes-pathnames";
import {
  PERMISSION_ROLE_BY_NAME,
  USER_ROLE,
} from "../../common/constants/common";

const NavigationBar = (props) => {
  const hash = window.location.hash;
  const urlRegexp = /(\\|\/)([^\\\/]+)/;

  const userInfo = props.userInfo;
  const isResellerControlled = props.isResellerControllingPublisher;

  const navLinks = getLinks(userInfo, isResellerControlled, props.publisherId);

  function handleNavClick(event, clickedPath) {
    if (clickedPath === props.location.pathname) {
      event.preventDefault();
    }
  }

  function handleIsNavActive(selectedNavPath, pathAliases, matchPath) {
    let matchingPath = selectedNavPath;
    if (matchPath) {
      matchingPath = matchPath;
    }

    const regexp = new RegExp(matchingPath.match(urlRegexp)[0]);
    let matches = hash.match(regexp);

    if (pathAliases) {
      pathAliases.forEach((alias) => {
        if (hash.match(new RegExp(alias.match(urlRegexp)[0]))) {
          matches = true;
        }
      });
    }

    return matches;
  }

  return (
    <Navbar
      style={{
        backgroundColor: "#0f182c",
        boxShadow: "0px 4px 12px 0px rgba(8, 1, 1, 0.45)",
      }}
      className="pt-0 pb-0"
      variant="dark"
    >
      <Nav>
        {navLinks.map((nav, i) => {
          return (
            <Nav.Link
              key={i}
              as={NavLink}
              to={nav.path}
              isActive={() =>
                handleIsNavActive(nav.path, nav.aliases, nav.matchPath)
              }
              style={{ marginRight: "20px" }}
              activeStyle={{
                // color: "#ff5622",
                fontWeight: "bold",
                borderBottom: "4px solid #ff5622",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
              onClick={(event) => {
                return handleNavClick(event, nav.path);
              }}
            >
              {nav.title} {nav.renderChildren && nav.renderChildren()}
            </Nav.Link>
          );
        })}
      </Nav>
      {/* <Nav className="ml-auto" style={{ marginRight: "8px" }}>
        <Nav.Link as={NavLink} to="/support/center">
          <div
            className="text-center"
            style={{
              backgroundColor: "gray",
              color: "white",
              borderRadius: "12px",
              height: "24px",
              width: "24px"
            }}
          >
            {"?"}
          </div>
        </Nav.Link>
      </Nav> */}
    </Navbar>
  );
};

function getLinks(userInfo, isResellerControlled, publisherId) {
  const role = userInfo.role;
  let links = [];

  if (isResellerControlled) {
    const prefexPath = "/publisher/";

    // replace xxx with the new publisher id: /publisher/xxx/something
    // 1. find index of last character of "/publisher/"
    // 2. find index of the next / (/ after xxx)
    // 3. replace it with the new string

    links = RESELLER_CONTROLLING_PUBLISHER_LINKS.map((link) => {
      const path = link.path;
      const start = path.substring(path.indexOf(prefexPath), prefexPath.length);
      const middle = publisherId;
      const remains = path.substring(prefexPath.length);
      const end = remains.substring(remains.indexOf("/"));
      link.path = start + middle + end;

      return link;
    });
  } else {
    switch (role) {
      case USER_ROLE.RESELLER_ADMIN: {
        links = RESELLER_LINKS;
        break;
      }

      case USER_ROLE.MANAGED_PUBLISHER_ADMIN: {
        links = MANAGED_PUBLISHER_LINKS;
        break;
      }

      case USER_ROLE.DIRECT_PUBLISHER_ADMIN: {
        // IMPORTANT! No need to show Subscription to VIP (accountType) users
        if (_.get(userInfo, "publisher.accountType") === "VIP") {
          links = _.filter(DIRECT_PUBLISHER_LINKS, (link) => {
            return link.path !== PATHNAME.subscriptionAndBilling.overview;
          });
          break;
        }

        links = DIRECT_PUBLISHER_LINKS;
        break;
      }
    }
  }

  // filter links by PERMISSION!
  let roleId = _.get(userInfo, "user.roleId");
  if (roleId === PERMISSION_ROLE_BY_NAME.OPERATOR) {
    links = _.filter(links, (link) => {
      return _.indexOf(OPERATOR_DISABLE_LINKS, link.key) === -1;
    });
  }
  if (roleId === PERMISSION_ROLE_BY_NAME.VIEWER) {
    links = _.filter(links, (link) => {
      return _.indexOf(VIEWER_DISABLE_LINKS, link.key) === -1;
    });
  }

  return links;
}

export default withRouter(NavigationBar);

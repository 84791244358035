import PATHNAME from "./routes-pathnames";
// import YbOptimizationIndicator from "common/components/yb-optimization-indicator";

const LINK_KEYS = {
  HOME: "HOME",
  INVENTORY: "INVENTORY",
  INVENTORY_ONBOARD_WIZARD: "INVENTORY_ONBOARD_WIZARD",
  ANALYTICS: "ANALYTICS",
  SUBSCRIPTIONS_BILLING: "SUBSCRIPTIONS_BILLING",
  USERS: "USERS",
  PUBLISHERS: "PUBLISHERS",
  RESELLER_BILLING: "RESELLER_BILLING",
  SETTINGS: "SETTINGS",
};

// Order Matters!
const directPublisherLinks = [
  {
    key: LINK_KEYS.HOME,
    title: "Home",
    path: PATHNAME.home,
  },
  {
    key: LINK_KEYS.INVENTORY,
    title: "Inventory",
    path: PATHNAME.inventorySummary,
    aliases: ["/yield-sets", "/inventory", "/network", "/ad-units"], // nav still active in these paths
  },
  // {
  //   title: "AdSense Ad Unit Allocation",
  //   path: PATHNAME.adSizeOptimization,

  //   // TODO: if this indicator is necessary, rewrite it in /_newapp
  //   // and use PublisherInfoContext to get publisherId in order to query under a specific publisher

  //   // renderChildren: () => {
  //   //   return <YbOptimizationIndicator />;
  //   // }
  // },
  {
    key: LINK_KEYS.INVENTORY_ONBOARD_WIZARD,
    title: "Inventory Onboard Wizard",
    path: PATHNAME.onBoardWizard,
  },
  {
    key: LINK_KEYS.ANALYTICS,
    title: "Analytics",
    path: PATHNAME.dashboardOverview,
  },
  {
    key: LINK_KEYS.SUBSCRIPTIONS_BILLING,
    title: "Subscription & Billing",
    path: PATHNAME.subscriptionAndBilling.overview,
  },
  {
    key: LINK_KEYS.USERS,
    title: "Users",
    path: PATHNAME.users,
  },
  // {
  //   title: "Settings",
  //   path: PATHNAME.settings
  // }
];

const managedPublisherLinks = [
  {
    key: LINK_KEYS.HOME,
    title: "Home",
    path: PATHNAME.home,
  },
  {
    key: LINK_KEYS.INVENTORY,
    title: "Inventory",
    path: PATHNAME.inventorySummary,
  },
  // {
  //   title: "AdSense Ad Unit Allocation",
  //   path: PATHNAME.adSizeOptimization,
  // },
  {
    key: LINK_KEYS.INVENTORY_ONBOARD_WIZARD,
    title: "Inventory Onboard Wizard",
    path: PATHNAME.onBoardWizard,
  },
  {
    key: LINK_KEYS.ANALYTICS,
    title: "Analytics",
    path: PATHNAME.dashboardOverview,
  },
  {
    key: LINK_KEYS.USERS,
    title: "Users",
    path: PATHNAME.users,
  },
  // {
  //   title: "Settings",
  //   path: PATHNAME.settings
  // }
];

const resellerLinks = [
  {
    key: LINK_KEYS.HOME,
    title: "Home",
    path: PATHNAME.home,
  },
  {
    key: LINK_KEYS.PUBLISHERS,
    title: "Publishers",
    path: PATHNAME.publishers,
  },
  {
    key: LINK_KEYS.ANALYTICS,
    title: "Analytics",
    path: PATHNAME.resellerDashboard,
  },
  {
    key: LINK_KEYS.RESELLER_BILLING,
    title: "Billing",
    path: PATHNAME.resellerBilling,
  },
  {
    key: LINK_KEYS.USERS,
    title: "Users",
    path: PATHNAME.users,
  },
  {
    key: LINK_KEYS.SETTINGS,
    title: "Settings",
    path: PATHNAME.settings,
  },
];

const resellerControllingPublisherLinks = [
  {
    key: LINK_KEYS.INVENTORY,
    title: "Inventory",
    path: PATHNAME.publisherBase.inventorySummary,
    matchPath: PATHNAME.publisherBase.inventorySummary.substr(
      PATHNAME.publisherBase.basePath.length
    ),
    aliases: ["/yield-sets", "/inventory", "/network", "/ad-units"],
  },
  // {
  //   title: "AdSense Ad Unit Allocation",
  //   path: PATHNAME.publisherBase.adSizeOptimization,
  //   matchPath: PATHNAME.publisherBase.adSizeOptimization.substr(
  //     PATHNAME.publisherBase.basePath.length
  //   ),
  // },
  {
    key: LINK_KEYS.INVENTORY_ONBOARD_WIZARD,
    title: "Inventory Onboard Wizard",
    path: PATHNAME.publisherBase.onBoardWizard,
    matchPath: PATHNAME.publisherBase.onBoardWizard.substr(
      PATHNAME.publisherBase.basePath.length
    ),
  },
  // {
  //   title: "Settings",
  //   path: PATHNAME.publisherBase.settings,
  //   matchPath: PATHNAME.publisherBase.settings.substr(
  //     PATHNAME.publisherBase.basePath.length
  //   )
  // }
];

export const DIRECT_PUBLISHER_LINKS = directPublisherLinks;
export const MANAGED_PUBLISHER_LINKS = managedPublisherLinks;
export const RESELLER_LINKS = resellerLinks;
export const RESELLER_CONTROLLING_PUBLISHER_LINKS =
  resellerControllingPublisherLinks;
export const OPERATOR_DISABLE_LINKS = [
  LINK_KEYS.RESELLER_BILLING,
  LINK_KEYS.SETTINGS,

  LINK_KEYS.SUBSCRIPTIONS_BILLING,
  LINK_KEYS.USERS,
];
export const VIEWER_DISABLE_LINKS = [
  LINK_KEYS.RESELLER_BILLING,
  LINK_KEYS.SETTINGS,

  LINK_KEYS.INVENTORY_ONBOARD_WIZARD,
  LINK_KEYS.SUBSCRIPTIONS_BILLING,
  LINK_KEYS.USERS,
];

import gql from "graphql-tag";

export const GET_ASSOCIATED_PUBLISHERS = gql`
  query GET_ASSOCIATED_PUBLISHERS {
    me {
      userId
      email
      associatedPublishers {
        publisherId
        name
        type
        registered
        accountType
        reseller {
          publisherId
          name
        }
      }
    }
  }
`;

export const GET_USER_INFO = gql`
  query GET_USER_INFO {
    me {
      userId
      email
      roleId
      associatedPublishers {
        publisherId
        name
        type
        registered
        accountType
        geoCountry {
          name
          countryId
          alpha2
        }
        billingSetting {
          activeBillingProfile {
            profileId
            method
          }
        }
        reseller {
          publisherId
          name
        }
      }
    }
    publisherAccountStatus {
      status {
        trialStatus {
          remainingDays
          billableStartDate
        }
      }
    }
    accountConfig {
      config
    }
  }
`;

export const GET_USER_INFO_OLD = gql`
  query GET_USER_INFO {
    me {
      userId
      email
      publisher {
        publisherId
        name
        type
        registered
        geoCountry {
          name
          countryId
          alpha2
        }
        billingSetting {
          activeBillingProfile {
            profileId
            method
          }
        }
        accountType
        reseller {
          publisherId
          name
        }
      }
    }
    publisherAccountStatus {
      status {
        trialStatus {
          remainingDays
          billableStartDate
        }
      }
    }
    accountConfig {
      config
    }
  }
`;

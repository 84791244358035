import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PATHNAME from "../constants/routes-pathnames";
import { PERMISSION_ROLE, USER_ROLE } from "../../common/constants/common";

const SubscribeCtaHeader = (props) => {
  const userInfo = props.userInfo;
  const accountType = _.get(userInfo, "publisher.accountType");
  const hasSubscribePermission =
    _.get(userInfo, "user.roleId") === PERMISSION_ROLE.ADMIN;

  const [isButtonHovered, setIsButtonHovered] = useState(false);

  let isDirectPubAndNotSubscribed = false;
  if (userInfo.role === USER_ROLE.DIRECT_PUBLISHER_ADMIN) {
    if (accountType === "TRIAL" || accountType === "TRIAL_EXPIRED") {
      isDirectPubAndNotSubscribed = true;
    }
  }
  const [shouldShow, setShouldShow] = useState(false);

  const pathsToHide = _.reduce(
    PATHNAME.subscriptionAndBilling,
    (result, p) => {
      result.push(p);
      return result;
    },
    []
  );

  useEffect(() => {
    if (!isDirectPubAndNotSubscribed) return;

    // if user is on the page the cta is redirected to
    if (
      props.location.pathname.match(PATHNAME.subscriptionAndBilling.overview)
    ) {
      setShouldShow(false);
    }

    // if user is on one of the pages we specified to hide
    const isOnHiddenPath = pathsToHide.some((p) => {
      return props.location.pathname.match(p);
    });

    setShouldShow(!isOnHiddenPath);
  }, [props.location.pathname]);

  function gotToPath() {
    props.history.push(PATHNAME.subscriptionAndBilling.overview);
  }

  function toggleHover() {
    setIsButtonHovered(!isButtonHovered);
  }

  return (
    <React.Fragment>
      {shouldShow && (
        <div
          style={{
            height: "36px",
            backgroundColor: "#192952",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#fafafa",
              fontSize: "smaller",
              alignSelf: "center",
            }}
          >
            {accountType === "TRIAL_EXPIRED"
              ? "Your free trial has expired! Subscribe to restart the boost!"
              : "Feel the boost? Subscribe and keep the boost up!"}

            {hasSubscribePermission && (
              <button
                type="button"
                style={{
                  borderRadius: "5px",
                  backgroundColor: isButtonHovered ? "#ff3d00" : "#e83902",
                  color: "#ffffff",
                  border: "none",
                  fontSize: "smaller",
                  marginLeft: "12px",
                  cursor: "pointer",
                  boxShadow: "0px 2px 0px #8e2200",
                  fontFamily: "roboto",
                }}
                onClick={gotToPath}
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}
              >
                Subscribe Now
              </button>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default withRouter(SubscribeCtaHeader);

import React from "react";

import CommonStyle from "common/styles/common.css";
import CreateUserModal from "./create-user-modal";

class CreateUserBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({
      showModal: true,
    });
  }

  closeModal(refetch, unused, data) {
    this.setState({
      showModal: false,
    });
  }

  render() {
    var inModal = this.state.showModal;
    var btnClass = this.props.btnClass || "";

    return (
      <div style={{ display: "inline-block" }}>
        <span
          className={`${CommonStyle.button} ${btnClass}`}
          onClick={this.openModal}
        >
          <i className="fa fa-plus" /> Add user
        </span>
        <CreateUserModal
          inModal={inModal}
          closeModal={this.closeModal}
          refetch={this.props.refetch}
        ></CreateUserModal>
      </div>
    );
  }
}

module.exports = CreateUserBtn;

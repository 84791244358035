import React from "react";
import { Query, Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import YbTooltip from "common/components/yb-tooltip";
import YbLoading from "common/components/yb-loading";
import YbIndicator from "common/components/yb-indicator";

import Styles from "../styles/summary.css";
import YbYieldSet from "../components/yb-yield-set";
import YbStatus from "../components/yb-status";
import YbHider from "../components/yb-hider";
import {
  SYNC_AD_UNIT,
  TOGGLE_INVENTORY,
  NETWORK_PROBLEMS,
} from "../query/summary";
import VerifyNetworkButton from "./verify-network-button";
import NetworkStatusChecker from "./network-status-checker";

class YbNetwork extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionRef: null,
      publisherId: props.publisherId,
    };
  }

  componentDidMount() {
    this.state.collectionRef = this.refs.yieldSets;
  }

  toggleNetwork(network, event) {
    return this.props.toggleNetwork(network, event);
  }

  syncAdUnits(network, syncAdUnit, event) {
    return this.props.syncAdUnits(network, syncAdUnit, event);
  }

  networkConfiguration(network, event) {
    if (this.state.publisherId > 0) {
      this.props.history.push(
        `/publisher/${this.state.publisherId}/network/${network.networkId}/accounts`
      );
    } else {
      this.props.history.push(`/network/${network.networkId}/accounts`);
    }

    event.stopPropagation();
  }

  newYieldSet(network, event) {
    if (this.state.publisherId > 0) {
      this.props.history.push(
        `/publisher/${this.state.publisherId}/yield-sets/create/${network.networkId}`
      );
    } else {
      this.props.history.push(`/yield-sets/create/${network.networkId}`);
    }

    event.stopPropagation();
  }

  getRunnableItems(yieldSet, isUnits, stopable) {
    return yieldSet[isUnits ? "adUnits" : "placements"].filter((adUnit) => {
      if (stopable) {
        return adUnit.status === "RUNNING" && adUnit.selected;
      }

      return adUnit.status === "PAUSED" && adUnit.selected;
    });
  }

  toggleInventoryItemsStatus(
    adUnits,
    placements,
    isStop,
    request,
    refetch,
    event
  ) {
    var status = isStop ? "PAUSED" : "RUNNING";
    var params = {
      variables: {
        adUnitInput: {
          status: status,
        },
        placementInput: {
          status: status,
        },
        pubId: this.state.publisherId,
      },
    };

    params.variables.adUnitInput.adUnitIds = adUnits.map((adUnit) => {
      adUnit.selected = false;
      return adUnit.adUnitId;
    });

    params.variables.placementInput.placementIds = placements.map(
      (placement) => {
        placement.selected = false;
        return placement.placementId;
      }
    );

    request(params).then(() => {
      refetch();
    });

    event.stopPropagation();
  }

  renderToggleBtn(adUnits, placements, refetch, isStop) {
    if (!adUnits.length && !placements.length) {
      return <span />;
    }

    return (
      <Mutation mutation={TOGGLE_INVENTORY}>
        {(toggleStatusRequest, { loading, error, data }) => {
          var iconClass = "fa fa-play";
          var buttonTooltip = i18n`RUN_INVENTORY_ITEM_S`;

          if (loading) return <YbLoading />;

          if (data || error) {
            //this request is not returning data?
            refetch();
          }

          if (isStop) {
            iconClass = "fa fa-pause";
            buttonTooltip = i18n`PAUSE_INVENTORY_ITEM_S`;
          }

          return (
            <YbTooltip message={buttonTooltip} position="left">
              <span
                className={`${CommonStyle.button} ${Styles.yieldSetBtn} ${
                  isStop ? Styles.stop : Styles.run
                }`}
                onClick={this.toggleInventoryItemsStatus.bind(
                  this,
                  adUnits,
                  placements,
                  isStop,
                  toggleStatusRequest,
                  refetch
                )}
              >
                <i className={iconClass} /> (
                {adUnits.length + placements.length})
              </span>
            </YbTooltip>
          );
        }}
      </Mutation>
    );
  }

  renderYieldSets(network, refetch) {
    var accountConfig = this.props.accountConfig;

    return network.matchedYieldSets.map((yieldSet, yieldSetIxdex) => {
      var runnableUnits = this.getRunnableItems(yieldSet, true);
      var runnablePlacements = this.getRunnableItems(yieldSet, false);
      var stopabbleUnits = this.getRunnableItems(yieldSet, true, true);
      var stopabblesPlacements = this.getRunnableItems(yieldSet, false, true);

      return (
        <YbYieldSet
          publisherId={this.state.publisherId}
          totalAdUnits={network.totalAdUnits}
          totalPlacements={network.totalPlacements}
          accountConfig={accountConfig}
          key={yieldSetIxdex}
          yieldSetIndex={yieldSetIxdex}
          network={network}
          yieldSet={yieldSet}
          refetch={refetch}
          stopBtn={this.renderToggleBtn(
            runnableUnits,
            runnablePlacements,
            refetch
          )}
          runBtn={this.renderToggleBtn(
            stopabbleUnits,
            stopabblesPlacements,
            refetch,
            true
          )}
          showAdUnits={
            this.props.accordion.yieldSet[yieldSet.yieldSetId].showAdUnits
          }
          showPlacements={
            this.props.accordion.yieldSet[yieldSet.yieldSetId].showPlacements
          }
          showContent={
            this.props.accordion.yieldSet[yieldSet.yieldSetId].showContent
          }
          accordion={this.props.accordion}
          toggleYieldSet={this.props.toggleYieldSet}
          toggleYieldSetContent={this.props.toggleYieldSetContent}
          toggleInventoryItemStatus={this.props.toggleInventoryItemStatus}
          history={this.props.history}
        />
      );
    });
  }

  countProblems(collection) {
    return collection.edges
      .map((edge) => {
        if (!edge.node.problems.length) {
          return false;
        }

        return edge.node.problems.indexOf("NORMAL") === -1;
      })
      .filter((haveProblem) => haveProblem).length;
  }

  _matchWords(searchText, matchText) {
    const st = searchText.toLowerCase();
    const mt = matchText.toLowerCase();
    return mt.includes(st);
  }

  render() {
    var accordion = this.props.accordion;
    var network = this.props.network;
    var networkIndex = this.props.networkIndex;
    var searchText = this.props.searchText;
    var showYieldSets = accordion.network[network.networkId].showYieldSets;
    var networksAmount = this.props.networksAmount;
    var accountConfig = this.props.accountConfig;
    var refetch = this.props.refetch;
    var collectionRef = this.state.collectionRef;
    var canCreateYieldSets = accountConfig.yieldSet.enabled;
    var regex;
    try {
      // need to escape characters for regex to find special characters like '()'
      regex = new RegExp(escape(searchText), "i");
    } catch (err) {
      console.log(err);
    }
    var counter = 0;
    var variables = {
      adxFilter: {
        networkId: network.networkId,
      },
      adsenseFilter: {
        networkId: network.networkId,
      },
      pubId: this.state.publisherId,
    };

    network.totalAdUnits = network.yieldSets.edges.reduce(
      (result, yieldSet) => {
        return result + yieldSet.node.gamAdUnits.edges.length;
      },
      0
    );

    network.totalPlacements = network.yieldSets.edges.reduce(
      (result, yieldSet) => {
        return result + yieldSet.node.gamPlacements.edges.length;
      },
      0
    );

    if (accountConfig.yieldSet.yieldSetCap !== -1) {
      if (
        network.yieldSets.edges.length >= accountConfig.yieldSet.yieldSetCap
      ) {
        canCreateYieldSets = false;
      }
    }

    network.matchedYieldSets = network.yieldSetsVM.filter((yieldSet) => {
      yieldSet.matchedAdUnits = yieldSet.adUnits.filter((adUnit) => {
        if (
          !this._matchWords(searchText, adUnit.name) &&
          !this._matchWords(searchText, adUnit.parentPath)
        ) {
          adUnit.selected = false;
          return false;
        }

        return adUnit;
      });

      yieldSet.matchedPlacements = yieldSet.placements.filter((placement) => {
        if (!placement.name.match(regex)) {
          placement.selected = false;
          return false;
        }

        return placement;
      });

      return !(
        searchText &&
        !yieldSet.matchedAdUnits.length &&
        !yieldSet.matchedPlacements.length
      );
    });

    counter = network.matchedYieldSets.filter((yieldSet) =>
      yieldSet.problems.find((problem) => problem !== "NORMAL")
    ).length;

    return (
      <div
        key={networkIndex}
        className={
          Styles.network +
          " " +
          (showYieldSets === null
            ? ""
            : showYieldSets
            ? Styles.showYieldSets
            : Styles.hideYieldSets)
        }
      >
        <div
          className={`row ${Styles.titleWrapper} align-items-center`}
          onClick={this.toggleNetwork.bind(this, network)}
        >
          <div className="col-md-8">
            <span
              className={`${Styles.caret} ${CommonStyle.problemIndicatorWrapper}`}
            >
              {showYieldSets ? (
                <i className="fa fa-angle-down" />
              ) : (
                <span>
                  <i className="fa fa-angle-right" />
                  <YbIndicator counter={counter} />
                </span>
              )}
            </span>
            <span>
              {network.name} ({network.matchedYieldSets.length})
            </span>
            <YbStatus
              item={network}
              index={networkIndex}
              total={networksAmount}
              showStatus={false}
            />
          </div>
          <div className="col-md-4">
            <NetworkStatusChecker
              network={network}
              publisherId={this.state.publisherId}
              gamAccountId={this.props.gamAccountId}
            >
              {({ loading, error, status }) => {
                if (loading) {
                  return "";
                }

                if (error) {
                  return "Failed to get network status";
                }

                if (status === "PROCESSING") {
                  return (
                    <div className="d-flex justify-content-end align-items-center">
                      <YbTooltip
                        message="Please wait until we finish verifying to continue any inventory management"
                        position="left"
                      >
                        <YbLoading darkBackground={true} />
                      </YbTooltip>
                    </div>
                  );
                } else if (status === "FAILED") {
                  return (
                    <div className="d-flex justify-content-end align-items-center">
                      <span className="text-danger mr-1">
                        Failed to verify network
                      </span>
                      <VerifyNetworkButton
                        networkId={network.networkId}
                        publisherId={this.state.publisherId}
                        refetch={refetch}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className={Styles.actionsWrapper}>
                      <div>
                        {/* {" "}
                        <VerifyNetworkButton
                        networkId={network.networkId}
                          publisherId={this.state.publisherId}
                          syncAdUnits={this.syncAdUnits}
                          refetch={refetch}
                        /> */}
                        {canCreateYieldSets ? (
                          <YbTooltip
                            message={i18n`NEW_YIELD_SET`}
                            position="left"
                          >
                            <span
                              className={`${CommonStyle.button} ${Styles.networkBtn}`}
                              onClick={this.newYieldSet.bind(this, network)}
                            >
                              <i className="fa fa-plus" />
                            </span>
                          </YbTooltip>
                        ) : (
                          <span
                            className={`${CommonStyle.button} ${Styles.networkBtn}`}
                            disabled
                          >
                            <i className="fa fa-plus" />
                          </span>
                        )}
                        <Mutation mutation={SYNC_AD_UNIT}>
                          {(syncAdUnit, { loading, error, data }) => {
                            if (loading) {
                              return <YbLoading darkBackground={true} />;
                            }

                            if (error) {
                              return (
                                <YbTooltip
                                  message={i18n`ERROR_SYNCHRONIZING_AD_UNITS_ELLIPSIS`}
                                  position="left"
                                >
                                  <span className={CommonStyle.button} disabled>
                                    <i className="fa fa-refresh text-danger" />
                                  </span>
                                </YbTooltip>
                              );
                            }

                            if (data) {
                              refetch();
                            }

                            if (!accountConfig.gamNetwork.enabled) {
                              return (
                                <span
                                  className={`${CommonStyle.button} ${Styles.networkBtn}`}
                                  disabled
                                >
                                  <i className="fa fa-refresh" />
                                </span>
                              );
                            }

                            return (
                              <YbTooltip
                                message={i18n`SYNC_NETWORK`}
                                position="left"
                              >
                                <span
                                  className={`${CommonStyle.button} ${Styles.networkBtn}`}
                                  onClick={this.syncAdUnits.bind(
                                    this,
                                    network,
                                    syncAdUnit
                                  )}
                                >
                                  <i className="fa fa-refresh" />
                                </span>
                              </YbTooltip>
                            );
                          }}
                        </Mutation>

                        <YbTooltip
                          message={i18n`NETWORK_SETTINGS`}
                          position="left"
                        >
                          <span
                            className={`${CommonStyle.button} ${Styles.networkBtn} ${CommonStyle.problemIndicatorWrapper}`}
                            onClick={this.networkConfiguration.bind(
                              this,
                              network
                            )}
                            disabled={!accountConfig.gamNetwork.enabled}
                          >
                            <i className="fa fa-cog" />
                            <Query
                              query={NETWORK_PROBLEMS}
                              variables={variables}
                            >
                              {({ loading, error, data }) => {
                                var counter = 0;

                                if (loading || error) {
                                  return <span />;
                                }

                                if (data) {
                                  counter =
                                    this.countProblems(data.adxAccounts) +
                                    this.countProblems(data.adsenseAccounts);

                                  return <YbIndicator counter={counter} />;
                                }

                                return <span />;
                              }}
                            </Query>
                          </span>
                        </YbTooltip>
                      </div>
                    </div>
                  );
                }
              }}
            </NetworkStatusChecker>
          </div>
        </div>
        <div className={`${Styles.yieldSets}`} ref="yieldSets">
          {this.renderYieldSets(network, refetch)}
        </div>
        {showYieldSets && (
          <YbHider collectionRef={collectionRef} lineHeight={31} />
        )}
      </div>
    );
  }
}

module.exports = YbNetwork;

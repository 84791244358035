import React from "react";

import CommonStyle from "common/styles/common.css";
import UpdateUserModal from "./update-user-modal";

class UpdateUserBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({
      showModal: true,
    });
  }

  closeModal(refetch, unused, data) {
    this.setState({
      showModal: false,
    });
  }

  render() {
    var inModal = this.state.showModal;
    var btnClass = this.props.btnClass || "";
    const { user, isDisabled } = this.props;

    return (
      <div style={{ display: "inline-block" }}>
        <button
          type="button"
          className={`${CommonStyle.button} ${btnClass}`}
          onClick={this.openModal}
          disabled={isDisabled}
        >
          Change role
        </button>
        <UpdateUserModal
          user={user}
          inModal={inModal}
          closeModal={this.closeModal}
          refetch={this.props.refetch}
        ></UpdateUserModal>
      </div>
    );
  }
}

module.exports = UpdateUserBtn;
